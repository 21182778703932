.projects {
	&__title {
		@apply my-5 uppercase text-3xl lg:text-5xl lg:container mx-auto font-bold text-colorMain pl-6 lg:pl-0;
	}

	&__wrapper {
		@apply w-full flex lg:gap-x-3 relative;
	}

	&__show-filter-btn {
		@apply text-base z-20 lg:hidden flex justify-start gap-1 items-center pl-6 lg:pl-0 mb-4 relative max-w-max;
	}

	&__filter {
		@apply hidden lg:block w-full lg:w-[30%] h-[900px] lg:h-full nik:w-1/4 xl:w-1/5 bg-colorDarkGray 
		lg:px-1;
	}

	&__filter_mobile {
		@apply flex justify-center items-center lg:hidden w-full bg-colorDarkGray 
		px-0 z-10 top-10 origin-top duration-300 z-30;
	}

	&__houses {
		@apply w-full lg:mx-auto lg:w-[70%] nik:w-3/4 xl:w-4/5;

		&-title {
			@apply text-base md:text-xl lg:text-2xl pl-6 lg:pl-0;
		}

		& div:first-child {
			@apply flex items-center;
		}
	}

	&__block {
		@apply p-5 flex flex-wrap gap-y-6 gap-x-4;
	}
}
