.fullGallery {
	@apply md:container py-4 mx-4 md:mx-auto;

	&__title {
		@apply py-8 uppercase text-xl sm:text-3xl lg:text-4xl font-bold text-colorMain;
	}

	&__grid {
		@apply grid grid-cols-2 auto-rows-auto;
	}

	&__image {
		@apply w-full h-[130px] sm:h-[200px] md:h-[250px] lg:h-[300px] nik:h-[350px] xl:h-[400px] bg-gray-100;

		&:hover img {
			@apply scale-110;
		}

		& img {
			@apply w-full h-full object-cover transition-all duration-300 ease-out cursor-pointer;
		}
	}

}
