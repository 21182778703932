.house {
	@apply w-[full] text-colorDarkGray h-full xl:h-[500px] no-underline;


	&__mansarda {
		@apply py-2 px-3 bg-colorMain absolute min-w-max top-5 left-5 z-10 rounded-md text-[10px] leading-tight md:text-sm font-semibold uppercase;
	}

	&:hover img {
		transform: scale(1.05);
	}

	.v-divider {
		@apply text-base xl:text-xl mx-1 xl:mx-2 font-normal;
	}

	&__image {
		@apply h-[250px] sm:h-[400px] md:h-80 xl:h-full w-full overflow-hidden;

		& img {
			@apply h-full w-full object-cover transition-all duration-300 ease-linear;
		}
	}

	&__data {
		@apply absolute bottom-7 py-1 lg:py-2 px-1 lg:px-2 nik:px-8 min-w-max tracking-tighter bg-colorMain font-bold text-[10px] leading-tight sm:text-sm md:text-xs nik:text-sm xl:text-base no-underline;
	}

	&__price {
		@apply text-xs md:text-base font-semibold absolute -right-1 -bottom-3 p-1 md:p-2 min-w-max bg-slate-100;
	}
}
