.defForm{
    @apply flex flex-col items-center justify-start bg-gray-100 py-8;

    .error{
        @apply text-xs text-red-600;
    }

    &__title{
        @apply text-center text-2xl lg:text-4xl text-colorDarkGray px-4 md:px-0;
    }

    &__sub-title{
        @apply my-5 text-center text-sm lg:text-2xl text-colorDarkGray px-4 md:px-0;
    }

    &__wrapper{
        @apply flex flex-col md:flex-row justify-center xl:gap-[100px] gap-10;
    }

    &__block{
        @apply flex flex-col;
    }

    &__block label{
        @apply font-medium text-sm lg:text-base xl:text-xl;
    }

    &__input{
        @apply py-3 px-6 outline-none border-none rounded-md text-sm xl:text-base transition-all ease-linear duration-300 hover:bg-gray-300 focus:bg-gray-300;
    }

    &__btn{
        @apply uppercase mt-6 cursor-pointer border-2 border-colorMain font-bold text-white text-base lg:text-xl py-2 px-6 bg-colorMain transition-all duration-300 ease-linear 
        hover:bg-transparent hover:text-colorDarkGray  disabled:border-colorDarkGray disabled:bg-colorDarkGray disabled:hover:bg-colorDarkGray disabled:hover:text-white;
    }

    &__check{
        @apply mt-5;
    }

    &__policy{
        @apply mr-1;
    }

    .policy-label{
        @apply float-right text-xs md:text-sm;
    }

}
