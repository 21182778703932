.build-block {
	@apply w-full md:w-1/3 md:px-0 cursor-pointer;

	&1 {
		@apply w-full h-[250px] lg:h-[300px] nik:h-[350px] xl:h-[400px] overflow-hidden;
	}

	&:hover &__image {
		transform: scale(1.1);
	}

	&:hover &__bar {
		transform: scale(1.5);
	}

	&:hover &__title {
		@apply bottom-8 xl:bottom-12 text-colorMain;
	}

	&__image {
		@apply object-cover w-full h-full transition-all duration-300 ease-linear;
	}

	&__title {
		@apply uppercase text-center absolute w-full bottom-6 xl:bottom-8 text-base lg:text-xl text-white z-10 transition-all duration-300 ease-linear;
	}

	&__bar {
		@apply absolute bottom-0 left-0 z-0 w-full h-[70px] xl:h-[100px]  bg-colorDarkGray opacity-70 transition-all duration-300 ease-linear;
	}
}
