.blogsSwiper {
	--swiper-navigation-size: 50px;
	&__wrapper {
		@apply lg:ml-[20%] mb-12 lg:mb-24 relative px-4 lg:px-0;
	}

	&__my-button-next {
		@apply hidden lg:flex p-12 bg-colorMain items-center justify-center absolute right-[2vw] top-[25%]  translate-y-[25%] z-10 rounded-[50%] cursor-pointer
        duration-500 transition-all ease-linear hover:opacity-60;
	}

	&__shadow-box {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 13vw;
		background: linear-gradient(-90deg, #fffffff2, #fff0);
		z-index: 2;
	}

	&__header {
		@apply lg:-rotate-90 lg:absolute -left-32 xl:-left-52 top-10 uppercase font-medium mb-4 lg:mb-0 text-4xl lg:text-5xl text-colorMain;
	}

	&__swiper {
		@apply h-[210px] sm:h-[280px] md:h-[360px] lg:h-[460px] nik:h-[510px] xl:h-[610px] mt-0;

		&-slide {
			@apply h-full;
			&__image-container {
				@apply h-[70%] sm:h-[80%] overflow-hidden;

				&:hover img {
					@apply scale-110;
				}

				&:hover + h2 {
					@apply text-colorMain;
				}

				& img {
					@apply h-full w-full object-cover transition-all duration-500 ease-out;
				}
			}
		}

		&-title {
			@apply text-sm md:text-xl lg:text-2xl text-colorDarkGray mt-2 lg:mt-8 transition-all duration-500 ease-out;
		}
	}
}
