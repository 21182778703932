.footer {
	@apply bg-colorDarkGray mt-10 py-8;

	&__wrapper {
		@apply lg:container mx-auto px-4;
	}

	&__info {
		@apply flex sm:justify-start gap-4 flex-wrap lg:flex-nowrap mt-4 lg:mt-0 mx-2 lg:mx-0;
	}

	.logo-f {
		@apply h-full w-full object-contain;
	}

	&__content {
		@apply w-[47%] md:w-1/5 lg:w-1/4 flex flex-col;
	}

	.content-float {
		@apply lg:float-left mr-4 w-full lg:w-1/4 mx-2 lg:mx-0;
	}
	// flex flex-col items-center lg:items-start justify-center

	&__major-text {
		@apply text-white text-left text-xs lg:text-sm w-full mt-3;
		&_terms {
			@apply text-[10px] lg:text-xs flex flex-col gap-1 lg:gap-2 py-1 lg:py-2;

			& a {
				@apply hover:underline max-w-max;
			}
		}
	}

	&__major-text.adress {
		@apply w-[70%] text-left;
	}

	&__title {
		@apply text-colorMain font-bold text-sm nik:text-base uppercase;
	}

	&__content_many {
		@apply gap-2 w-[47%] md:w-1/3 lg:w-auto;
	}

	&__contacts {
		@apply flex flex-col;
	}

	&__link {
		@apply text-white no-underline hover:underline font-normal mt-2 lg:mt-4
		 text-xs nik:text-sm transition-all duration-500 ease-linear max-w-max max-h-min;
	}

	.tel-link {
	}

	.email-link {
	}

	&__bot {
	}

	.bot {
		@apply flex justify-between mt-6 lg:mt-12 mx-2 lg:mx-0;

		&__copy {
			@apply text-white font-light text-[10px] lg:text-xs leading-[115%] md:leading-normal pr-8 lg:pr-0;
		}

		&__social {
			@apply flex gap-2 pr-8 lg:pr-12;

			.social-svg {
				@apply w-[120px] lg:w-[50px] h-[40px] duration-300 cursor-pointer hover:scale-110;
			}
		}
	}
}
