.designings {
	&__title {
		@apply uppercase text-3xl nik:text-4xl xl:text-5xl font-bold mb-16 text-colorMain;
	}

	&__wrapper {
		@apply px-24 xl:px-60;
	}

	&__sub-title {
		@apply text-center uppercase font-bold text-2xl my-12;
	}

	&__header {
		@apply font-semibold text-xl;

		&-italic {
			@apply italic text-center;
		}
	}

	&__list {
		& li {
			@apply text-base my-2 flex items-center;
		}
	}

    .list-icon {
        @apply w-[25px] h-[25px] text-colorMain float-left mr-4;
    }

	&__image {
		@apply h-[400px] bg-colorMain;
		& img {
			@apply h-full w-full object-cover my-8;
		}
	}

	&__text {
		@apply my-8 text-base;

		&-center {
			@apply text-center w-full m-0;
		}
	}

    &__link{
        @apply text-colorMain hover:opacity-60 transition-all duration-300 ease-linear;
    }

    &__bottom-links {
        @apply flex justify-center gap-1 mt-16;

        .bottom-link {
            @apply no-underline text-white py-3 px-8 bg-colorDarkGray font-medium text-xl transition-all duration-300 ease-linear;
        }

        .more-houses-link {
            @apply bg-colorDarkGray hover:bg-colorMain hover:text-white;
        }

        .show-gallery-link {
            @apply bg-colorMain text-colorDarkGray hover:bg-colorDarkGray hover:text-white;
        }
    }
}
