.article {
	@apply w-full sm:w-[48%] lg:w-[32%] relative flex flex-col justify-between;

	&__wrapper-link{
		@apply max-w-min max-h-min;
	}

	&__image {
		@apply h-[250px] lg:h-[300px] nik:h-[350px] xl:h-[400px] overflow-hidden relative;

		box-shadow: 10px 10px 0 0 var(--color-main);

		&:hover img {
			transform: scale(1.1);
		}

		.article-img {
			@apply w-full h-full object-cover duration-300 transition-all ease-in-out;
		}
	}

	&__title {
		@apply uppercase text-sm lg:text-base xl:text-xl font-semibold my-3 lg:my-8;
	}

	&__text {
		@apply text-xs lg:text-sm xl:text-base mb-8;
	}

	&__btn {
		@apply max-w-min max-h-min;

		& button {
			@apply bg-colorMain py-2 px-6 border-2 border-colorMain
             uppercase font-semibold cursor-pointer hover:bg-transparent transition-all duration-300 ease-linear;
		}
	}
}
