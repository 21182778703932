.similar {
	@apply text-center lg:container mx-auto pl-4 lg:px-0;

	.swiper-similar {
		@apply h-80 w-full;

		&-slide {
			@apply h-full w-full;
		}
	}

	&__header {
		@apply uppercase text-3xl lg:text-4xl text-colorMain my-5 font-bold;
	}

	&__wrapper {
		@apply flex flex-col lg:flex-row items-center gap-8 lg:gap-3;
	}

	&__link {
		@apply w-full h-full sm:w-3/4 md:w-1/2 transform-none text-colorDarkGray text-sm nik:text-base flex-[1] font-semibold;
		&:hover .similar-img {
			transform: scale(1.1);
		}
	}

	&__image {
		@apply w-full h-[300px] lg:h-[250px] nik:h-[300px] xl:h-[350px] overflow-hidden;

		.similar-img {
			@apply w-full h-full object-cover transition-all duration-300 ease-linear;
		}
	}

	&__title {
		@apply absolute z-10 py-1 px-2 min-w-max lg:px-4 xl:px-8 bg-colorMain bottom-10 text-xs md:text-sm nik:text-base;
	}

	&__price {
		@apply absolute z-10 right-0 bottom-1 min-w-max p-1 md:p-2 bg-gray-200;
	}

	.v-divider {
		@apply text-xl mx-2 font-normal;
	}
}
