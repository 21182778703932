.cbForm {
	@apply bg-white w-[250px] sm:w-[400px] md:w-[500px] py-8 sm:py-12 md:py-16 px-4 sm:px-8 flex flex-col items-center justify-center rounded-xl relative;

	.error {
		@apply text-xs text-red-600 m-0 p-0;
	}

	&__wrapper {
		@apply fixed top-0 left-0 w-full h-full bg-opacity-40 bg-black flex justify-center items-center z-10 transition-all duration-300 ease-in;
	}

	&__title {
		@apply text-2xl font-semibold text-center;
	}

	&__text {
		@apply w-full mt-4 text-xs sm:text-sm text-center;
	}

	&__btn {
		@apply uppercase rounded-xl mb-4 cursor-pointer border-2 border-colorMain font-semibold text-white text-xs sm:text-xl py-1 sm:py-2 px-7 bg-colorMain transition-all duration-300 ease-linear 
        hover:bg-transparent hover:text-colorDarkGray  disabled:border-colorDarkGray disabled:bg-colorDarkGray disabled:hover:bg-colorDarkGray disabled:hover:text-white;
	}

	&__check {
		@apply flex justify-center text-base;
	}

	.policy-label {
		@apply pl-1 text-[8px] sm:text-sm lg:text-base;
	}
}
