.blogs {
	&__wrapper {
		@apply py-4 lg:py-16;

		& p{
			@apply nik:px-[10%] xl:px-[15%] px-0;
		}
	}

	&__title {
		@apply uppercase text-xl lg:text-3xl nik:text-4xl xl:text-5xl font-bold mb-8 nik:mb-16 text-colorMain;
	}

	&__image-1 {
		@apply h-[300px] md:h-[550px] w-full;

		& img {
			@apply w-full h-full object-contain;
		}
	}

	.image-2 {
		@apply w-full h-[200px] sm:h-[250px] sm:w-[300px] float-left mr-4;
	}

	&__sub-title {
		@apply text-center uppercase font-bold text-[18px] lg:text-2xl my-8;

		&_italic {
			@apply normal-case italic;
		}
	}

	&__header {
		@apply text-center uppercase text-base lg:text-xl font-semibold;
	}

    &__sub-header{
        @apply text-colorMain font-medium nik:px-[10%] xl:px-[15%] px-0;;
    }

	&__text {
		@apply my-8 text-sm lg:text-base;
	}
}
