.comForm {
	@apply w-full h-full;

	.error {
		@apply text-xs text-red-600 m-0 p-0;
	}

	&__wrapper {
		@apply flex flex-col justify-center items-center md:justify-start md:items-start px-4;
	}

	&__inputs {
		@apply flex flex-col mb-4 sm:mb-8;
	}

	&__input {
		@apply border border-solid border-gray-200 rounded-md py-2 px-6 mt-2 sm:mt-6 outline-none
        transition-all ease-linear duration-300 hover:bg-gray-300 focus:bg-gray-300;
	}

	&__btn {
		@apply uppercase rounded-xl mb-4 cursor-pointer border-2 border-colorMain font-semibold 
		text-white text-base lg:text-xl py-1 px-2 nik:py-2 nik:px-4 bg-colorMain transition-all duration-300 
		ease-linear hover:bg-transparent hover:text-colorDarkGray  disabled:border-colorDarkGray 
		disabled:bg-colorDarkGray disabled:hover:bg-colorDarkGray disabled:hover:text-white;
	}

	&__check {
		@apply mt-2 flex items-center justify-center gap-2;
	}

	.policy-label {
		@apply float-right text-xs md:text-sm;
	}
}
