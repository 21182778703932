.brusMat {
	@apply bg-gray-100;
	&__wrapper {
		@apply lg:container px-4 lg:px-0 mx-auto py-8 w-full;
	}

	&__why {
		@apply w-full flex flex-col md:flex-row items-center px-4 lg:px-0 justify-between;
	}

	&__header {
		@apply font-medium text-colorDarkGray text-2xl lg:text-3xl xl:text-4xl mb-5 xl:mb-10;
	}

	&__text {
		@apply w-full md:w-1/2;
		&-1 {
			@apply w-4/5 lg:w-[65%] mt-4 text-xs lg:text-sm nik:text-base;
		}
	}

	&__img {
		@apply w-full lg:w-1/2 h-[250px] sm:h-[300px] lg:h-[400px] mt-0;

		.brus-img {
			@apply w-full h-full object-contain;
		}
	}

	&__why-blocks {
		@apply flex flex-wrap gap-y-4 lg:flex-nowrap justify-center lg:justify-between mt-12 gap-1;

		.block {
			@apply w-[47%] lg:w-[25%] text-xs nik:text-sm xl:text-base;

			& span {
				@apply text-sm nik:text-base xl:text-xl font-normal;
			}

			& p {
				@apply mt-1 pr-2;
			}
		}
	}
}
