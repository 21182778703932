.blog {
	@apply lg:container py-4 mx-4 lg:mx-auto;

	&__title {
		@apply py-4 lg:py-8 uppercase text-xl md:text-3xl lg:text-4xl font-bold text-colorMain;
	}

	&__sub-title {
		@apply pb-4 lg:pb-8 uppercase text-base lg:text-xl font-semibold;
	}

	&__wrapper {
		@apply flex justify-between flex-wrap gap-x-1 gap-y-8;
	}
}
