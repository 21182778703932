.queries {
	@apply lg:container mx-auto px-4 lg:px-0;
	&__title {
		@apply font-bold text-3xl lg:text-4xl mb-12 text-colorDarkGray;
	}

	&__wrapper {
		@apply flex justify-between flex-wrap md:flex-nowrap gap-2;

		.block {
			@apply w-full flex flex-wrap md:flex-nowrap justify-center items-center gap-4 text-colorMain decoration-colorMain underline hover:no-underline;

			&__image {
				@apply w-[60px] xl:w-[80px] h-[60px] left-0;

				.block-image {
					@apply h-full w-full object-contain;
				}
			}
			& p {
				@apply text-xs lg:text-sm font-normal xl:text-base;
			}
		}
	}
}
