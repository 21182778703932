.finished {
    text-align: center;

    &__title {
        @apply text-3xl lg:text-4xl font-bold uppercase text-colorMain;
    }

    &__sub-title {
        @apply uppercase text-colorDarkGray font-semibold my-5 cursor-pointer duration-300 hover:text-colorMain;
    }

    &__projects {

        .swiper-finished {
            @apply xl:h-[400px] h-[300px] w-full;

            .swiper-button-prev,
            .swiper-button-next {
                @apply mx-[2%] lg:mx-[27%] transition-all font-bold;
            }

            .swiper-slide{
                @apply opacity-100 lg:opacity-50 transition-all duration-500 ease-linear;
            }

            .swiper-slide-next{
                @apply opacity-50 lg:opacity-100 transition-all duration-500 ease-linear;
            }

            .swiper-pagination-bullet {
                @apply w-2 h-2 opacity-100 transition-all duration-300 ease-linear bg-white;
            }

            .swiper-pagination-bullet-active {
                @apply bg-colorMain;
            }

            .swiper-pagination-bullet:hover {
                @apply bg-colorMain;
            }
        }


        .finished-slide {
            height: 100%;
            width: 100%;
        }

        .finished-content-wrapper {
            @apply w-full h-full relative text-center flex justify-center overflow-hidden;
            &:hover img{
                @apply scale-110;
            }
        }

        .finished-img {
            @apply w-full h-full z-[1] object-cover duration-500 transition-all ease-out;
        }

        .finished-title {
            @apply z-10 absolute uppercase mx-auto text-white text-sm nik:text-base font-medium bottom-8 max-w-max py-1 px-4 bg-colorDarkGray transition-all duration-300 ease-linear hover:bg-colorMain;
        }
    }

}