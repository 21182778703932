header {
	@apply bg-white z-[1000] px-4 w-full h-[--header-height] fixed;
	box-shadow: 0.1px 0.1px 5px -1px var(--color-darkGrey);

	nav {
		@apply lg:container py-0 md:py-2 lg:py-4 mx-auto flex justify-between items-center;
	}

	.nav-wrapper {
		@apply justify-between space-x-7 xl:space-x-12 hidden lg:flex;
	}

	.nav-links {
		@apply text-xs lg:text-base uppercase xl:text-base;

		&:hover > div {
			@apply scale-y-100;
		}
	}

	.nav-link {
		@apply duration-300 hover:text-colorMain;
	}

	.submenu-lg {
		@apply absolute z-50 px-16 w-full h-[450px] nik:h-[550px] xl:h-[700px] scale-y-0 origin-top duration-300 
    	top-[110px] xl:top-[120px] left-0 flex flex-wrap justify-center items-center gap-x-4 bg-white shadow;

		&__item {
			@apply w-[30%] xl:w-[28%] h-[46%] overflow-hidden;

			&:hover img {
				@apply scale-105;
			}
		}

		&__image {
			@apply w-full h-full duration-300  brightness-90;
		}

		&__text {
			@apply absolute bottom-6 left-5 text-white font-normal;
		}
	}

	.submenu {
		@apply absolute z-50 py-4 px-6 scale-y-0 origin-top duration-300 top-6 left-[-30px] min-w-max bg-white shadow;

		&__item {
			@apply text-xs lg:text-sm mt-2 p-2 normal-case w-full hover:text-colorMain duration-300 transition-all;
		}
	}

	.nav-action {
		@apply flex items-center justify-between gap-2 sm:gap-8;

		&-wrapper {
			@apply hidden lg:block;
		}

		&-contact {
			@apply flex lg:hidden justify-between gap-2;
		}
	}

	.nav-action-link {
		@apply font-medium duration-300 hover:text-colorMain text-base xl:text-base;
	}

	.nav-action-link.mobile {
		@apply text-xl;
	}

	.get-call-form {
		@apply border-dotted border-b-2 inline-block border-colorMain duration-300 hover:text-colorMain hover:cursor-pointer;
	}

	.get-call-form.mobile {
		@apply text-xl;
	}

	.nav-mobile {
		@apply flex lg:hidden;
	}
}
