.service {
	@apply w-full lg:w-[80%] h-[250px] xl:h-[350px] relative overflow-hidden;
	box-shadow: 10px 10px 0 0 var(--color-main);

	&-link {
		@apply w-[280px] lg:w-[31%] mt-0;
	}

	&:hover img {
		transform: scale(1.1);
	}

	&__header {
		@apply py-4 nik:py-6 xl:py-8 text-center font-semibold text-xl xl:text-2xl absolute bg-white w-full z-10;
	}

	&__image {
		@apply w-full h-full;

		& img {
			@apply w-full h-full object-cover transition-all duration-300 ease-linear;
		}
	}
}
