.mobileMenu {
	@apply absolute top-[60px] sm:top-20 md:top-[100px] left-0 w-0 scale-x-0 origin-left h-screen bg-white duration-300 transition-all ease-out;

	&.active {
		@apply w-full scale-x-100;
	}

	&__wrapper {
		@apply w-full text-black pb-8;
	}

	&__acc {
		@apply flex flex-col items-start relative;

		&-toggler {
			@apply flex justify-between items-center w-full px-4 py-3;

			& span {
				@apply mt-0;
			}
		}

		& h2 {
			@apply text-xl text-colorDarkGray cursor-pointer font-normal;
		}

		@keyframes expandHeight {
			0% {
				max-height: 0;
			}
			100% {
				max-height: 300px;
			}
		}

		@keyframes collapseHeight {
			0% {
				max-height: 300px;
			}
			100% {
				max-height: 0;
			}
		}

		&-content {
			@apply flex flex-col ml-4 origin-top duration-500 w-full
            transition-all animate-[expandHeight_0.3s_forwards] ease-linear overflow-hidden ;

			&.active {
				@apply h-full max-h-min;
			}

			&.--inactive {
				@apply animate-[collapseHeight_0.3s_forwards] max-h-0;
			}

			& a {
				@apply block text-sm text-colorDarkGray active:text-colorMain focus:text-colorMain px-4 py-3;
			}

			&_alone {
				@apply text-xl text-colorDarkGray cursor-pointer font-normal hover:text-colorMain px-4 py-3;
			}
		}
	}

	&__action {
		@apply mt-4 px-2;

		& li {
			@apply mt-4 flex items-center justify-start gap-x-2 text-base;
		}
	}
}
