.filter-form {
	&__inputs {
		@apply flex flex-col items-start justify-start;

		& div {
			@apply mt-1 flex items-center text-base gap-2 cursor-pointer hover:text-colorMain transition-all duration-300 ease-in;
		}
	}

	&__header {
		@apply mt-8 text-xl text-white;
	}

	&__clear {
		@apply mt-8;

		.clear-btn {
			@apply flex justify-center lg:w-[150px] nik:w-[180px] xl:w-[200px] items-center text-white bg-colorMain rounded-lg px-3 py-2 mt-8 hover:opacity-80 transition-all duration-300 ease-linear text-xs nik:text-sm xl:text-base;
		}

		.clear-icon {
			@apply text-base nik:text-2xl mr-1;
		}
	}
}
