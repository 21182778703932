/* Book */

@font-face {
  font-family: 'CorsicaLX';
  src: local('CorsicaLX'),
       url('./fonts/CorsicaLX-Book.ttf') format('truetype'),
       url('./fonts/CorsicaLX-Book.eot?#iefix') format('embedded-opentype'),
       url('./fonts/CorsicaLX-Book.woff') format('woff'); 
  font-weight: normal;
  font-style: normal;
}

/* Bold */

@font-face {
  font-family: 'CorsicaLX';
  src: local('CorsicaLX'),
       url('./fonts/CorsicaLX-Bold.ttf') format('truetype'),
       url('./fonts/CorsicaLX-Bold.eot?#iefix') format('embedded-opentype'),
       url('./fonts/CorsicaLX-Bold.woff') format('woff'); 
  font-weight: 700;
  font-style: normal;
}

/* SemiBold */

@font-face {
  font-family: 'CorsicaLX';
  src: local('CorsicaLX'),
       url('./fonts/CorsicaLX-SemiBold.ttf') format('truetype'),
       url('./fonts/CorsicaLX-SemiBold.eot?#iefix') format('embedded-opentype'),
       url('./fonts/CorsicaLX-SemiBold.woff') format('woff'); 
  font-weight: 600;
  font-style: normal;
}

/* Medium */

@font-face {
  font-family: 'CorsicaLX';
  src: local('CorsicaLX'),
       url('./fonts/CorsicaLX-Medium.ttf') format('truetype'),
       url('./fonts/CorsicaLX-Medium.eot?#iefix') format('embedded-opentype'),
       url('./fonts/CorsicaLX-Medium.woff') format('woff'); 
  font-weight: 500;
  font-style: normal;
}

/* Regular */

@font-face {
  font-family: 'CorsicaLX';
  src: local('CorsicaLX'),
       url('./fonts/CorsicaLX-Regular.ttf') format('truetype'),
       url('./fonts/CorsicaLX-Regular.eot?#iefix') format('embedded-opentype'),
       url('./fonts/CorsicaLX-Regular.woff') format('woff'); 
  font-weight: 400;
  font-style: normal;
}

/* Light */

@font-face {
  font-family: 'CorsicaLX';
  src: local('CorsicaLX'),
       url('./fonts/CorsicaLX-Light.ttf') format('truetype'),
       url('./fonts/CorsicaLX-Light.eot?#iefix') format('embedded-opentype'),
       url('./fonts/CorsicaLX-Light.woff') format('woff'); 
  font-weight: 300;
  font-style: normal;
}
