.FullProject {
	.full {
		&__wrapper {
		}

		&__title {
			@apply lg:container mx-auto z-0 mt-2 mb-4 text-3xl font-semibold px-4 xl:px-0;
		}

		&__content {
			@apply lg:container mx-auto flex flex-col lg:flex-row gap-3 px-4 xl:px-0;
		}

		&__tabs {
			@apply lg:container lg:mx-auto mt-8 lg:mt-16 px-4 xl:px-0;
		}

		&__finished {
			@apply relative h-[400px] xl:h-[500px] mt-20;
		}

		&__similar {
			@apply mt-8 lg:mt-16 pl-4 xl:px-0;
		}

		&__queries {
			@apply mt-8 lg:mt-16 px-4 xl:px-0;
		}

		&__currMaterial {
			@apply mt-8 lg:mt-16;
		}

		&__defForm {
			@apply mt-8 lg:mt-16;
		}

		&__socials {
			@apply mt-8 lg:mt-16;
		}
	}

	.content {
		&__swiper {
			@apply h-[300px] sm:h-[400px] xl:h-[500px] w-full lg:w-[70%] bg-colorDarkGray;

			.swiper-house {
				height: 100%;

				.house-slide {
					height: 100%;

					.slide-img {
						@apply w-full h-full object-cover cursor-pointer;
					}
				}
			}
		}

		&__data {
			@apply w-full sm:w-4/5 md:w-1/2 mx-auto lg:w-[30%] py-4 px-2 border-2 border-solid border-gray-200 text-center;
			border: 2px solid var(--color-lightGray);
		}
	}

	.data {
		&__area {
			@apply text-colorMain text-2xl font-medium flex justify-center items-center gap-x-5 mb-6;

			.area {
				@apply text-colorDarkGray font-semibold uppercase text-base pt-1;
			}
		}

		&__material {
			@apply text-base font-medium mb-2;

			span {
				@apply uppercase font-semibold text-colorDarkGray;
			}
		}

		.price-title {
			margin: 20px 10px;
		}

		&__buttons {
			.get-price-btn {
				@apply border-2 mt-8 border-colorDarkGray py-4 px-6 uppercase bg-colorDarkGray text-white font-bold text-xs nik:text-sm cursor-pointer duration-300 
        hover:bg-transparent hover:text-colorDarkGray 
        xl:text-base;
			}
		}
	}

	.counts {
		display: flex;
		justify-content: space-around;

		&__data-title {
			@apply my-2 text-xs font-semibold uppercase text-colorDarkGray;
		}

		&__data {
			margin-top: 20px;
			font-size: 22px;
			display: flex;
			align-items: center;
			gap: 10px;
			font-weight: 600;

			& img {
				@apply w-1/2;
			}
		}
	}

	.floors {
		@apply lg:container mx-auto mt-4 lg:mt-8 flex justify-between gap-2 lg:gap-5 px-4;

		.floor {
			@apply uppercase flex items-center gap-2 flex-wrap;
		}

		&__text {
			@apply font-semibold text-xs md:text-sm lg:text-base;
		}

		&__first {
			width: 49%;
		}

		&__second {
			width: 49%;
		}

		&__spec {
			@apply text-colorMain font-semibold sm:text-sm md:text-base lg:text-xl;
		}

		&__img {
			@apply w-full h-[200px] md:h-[300px] nik:h-[400px] object-contain cursor-pointer;
		}
	}

	.full__included {
		@apply container mx-auto px-4 xl:px-0;
		.inc-title {
			@apply uppercase text-colorMain text-3xl lg:text-4xl text-center my-10 font-bold;
		}

		.item-wrapper {
			@apply flex flex-wrap lg:flex-nowrap gap-4 lg:gap-0 justify-between text-colorDarkGray;

			.item {
				@apply text-center font-normal text-base flex flex-col items-center w-[45%] lg:w-1/4;
			}

			.item__image {
				@apply h-24 lg:h-36;
			}
		}
	}
}
