.designing{

    &__title{
        @apply py-8 uppercase text-4xl font-bold text-colorMain;
    }

    &__sub-title{
        @apply pb-8 uppercase text-xl font-semibold;
    }

    &__blocks{
        @apply flex justify-center items-center gap-8 px-4 xl:px-8;
    }
}