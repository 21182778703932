.terms {
	@apply container px-[10%] xl:px-[15%] mx-auto text-sm;

	&__title {
		@apply py-8 uppercase text-4xl font-bold text-colorMain;
	}

    &__header{
        @apply my-8;
    }
}
