.popupAnswer {
	@apply fixed top-0 left-0 w-full h-full bg-opacity-40 bg-black 
	flex justify-center items-center transition-all duration-300 ease-in;

	&__wrapper {
		@apply bg-white py-4 px-6 sm:py-8 sm:px-12 md:py-16 md:px-24 flex flex-col items-center justify-center;
	}

	&__title {
		@apply text-xl md:text-2xl font-semibold text-black text-center;
	}

	&__button {
        @apply uppercase mt-4 md:mt-8 cursor-pointer border-2
		 border-colorDarkGray font-semibold text-white text-sm md:text-base py-2 px-3 
		 bg-colorDarkGray transition-all duration-300 ease-linear 
        hover:bg-transparent hover:text-colorDarkGray;
	}

}
