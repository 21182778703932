.gallery-block{
    @apply  relative w-full sm:w-[45%] lg:w-[31%] text-colorDarkGray h-full xl:h-[400px] no-underline mb-4 lg:mb-16;

    &__image{
        @apply h-[200px] md:h-[250px] lg:h-[300px] nik:h-[350px] xl:h-[400px] overflow-hidden relative bg-gray-100;

		box-shadow: 10px 10px 0 0 var(--color-main);

        &:hover img {
			transform: scale(1.1);
            @apply transition-all duration-300 ease-in;
		}

		.gallery-block-img {
			@apply w-full h-full object-cover duration-300 transition-all ease-in-out;
		}
    }

    &__title{
        @apply mt-8 text-xs md:text-base uppercase font-semibold;
    }
}