@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./scss/fonts.scss";

* {
	--color-main: #fbbe00;
	--color-darkGrey: #393939;
	--color-lightGray: #c9c9c9;
	--header-height: 150px;
}

@media (max-width: 1540px) {
	* {
		--header-height: 120px;
	}
}

@media (max-width: 1025px) {
	* {
		--header-height: 100px;
	}
}

@media (max-width: 767px) {
	* {
		--header-height: 80px;
	}
}

@media (max-width: 479px) {
	* {
		--header-height: 60px;
	}
}

body {
	font-family: "CorsicaLX";
	@apply text-sm lg:text-base xl:text-xl font-normal relative;
}
