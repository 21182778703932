.socials {
	@apply mx-[15%] text-center my-16;

	&__title {
		@apply mb-6 md:mb-12 uppercase font-bold text-2xl sm:text-4xl;
	}

	&__sub-title {
		@apply font-medium;
	}

	&__wrapper {
		@apply flex justify-center gap-12 mt-6 md:mt-12;
	}

	&__link {
		@apply text-black;
	}

	&__img {
		@apply w-10 h-10 transition-all duration-300 ease-linear;
	}

	&__img:hover {
		@apply scale-110;
	}
}
