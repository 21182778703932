.swiper-section {
	position: relative;
	--swiper-navigation-size: 50px;

	.swiper-lg {
		@apply min-h-[500px] lg:min-h-[600px] w-full;

		.swiper-pagination {
			bottom: 20px;
		}
		.swiper-pagination-bullet {
			width: 12px;
			height: 12px;
			opacity: 1;
			transition: all 0.5s ease;
			background-color: #fff;
		}

		.swiper-pagination-bullet-active {
			background-color: var(--color-main);
		}

		.swiper-pagination-bullet:hover {
			background-color: var(--color-main);
		}

		.swiper-button-prev,
		.swiper-button-next {
			position: absolute;
			top: var(--swiper-navigation-top-offset, 50%);
			width: calc(var(--swiper-navigation-size) / 30 * 27);
			height: var(--swiper-navigation-size);
			transition: color 0.5s ease;
		}

		.swiper-button-prev:hover,
		.swiper-button-next:hover {
			color: #000;
			background-color: rgba(0, 0, 0, 0.5);
		}

		.slider-slide-1 {
			background-image: url("../../img/Buk.jpeg");

			background-image: url("../../img/Buk.webp");

			@apply min-h-[500px] lg:min-h-[600px] nik:min-h-[700px] xl:min-h-[800px] w-full bg-top bg-no-repeat bg-cover relative;

			.text-wrapper {
				@apply absolute bottom-[100px] sm:bottom-[30%] xl:bottom-[25%] max-w-[700px] flex flex-col items-start justify-start flex-wrap;
			}

			.rotated-text {
				@apply -rotate-90 bg-white px-2 font-semibold text-base md:text-xl absolute left-0;
			}

			.title {
				@apply text-white text-4xl md:text-5xl lg:text-6xl font-semibold ml-20;
			}

			.slide-btn {
				@apply mt-8 ml-20 py-2 xl:py-3 px-6 xl:px-8 no-underline text-colorDarkGray bg-white text-base lg:text-xl rounded-[10px] text-center 
        		font-medium cursor-pointer transition-all duration-300 ease-in-out opacity-75 hover:opacity-100;
			}
		}
	}
}
