.comContacts {
	&__title {
		@apply lg:container py-4 mx-auto uppercase text-4xl font-bold text-colorMain text-center;
	}

	&__general {
		@apply lg:container py-4 mx-4 lg:mx-auto flex flex-col md:flex-row justify-center items-center md:items-start gap-x-8;

		&-content {
			@apply text-center w-full mt-2 md:mt-0 md:w-1/4;

			& h3 {
				@apply text-xl text-colorMain mb-2 font-normal;
			}

			& p {
				@apply text-base break-words font-medium hover:text-colorMain transition-all duration-300 ease-out;
			}
		}
	}

	&__map {
		@apply h-[400px] nik:h-[500px] xl:h-[600px] w-full overflow-hidden rounded-xl my-16;
	}

	&__info {
		@apply lg:container py-4 mx-4 lg:mx-auto flex flex-col md:flex-row justify-center items-center md:items-start gap-y-4 gap-x-16  px-8;

		& h3 {
			@apply text-center font-medium text-2xl md:text-3xl mb-8;
		}
	}

	&__requis {
		@apply flex flex-col gap-y-2 lg:gap-y-4 w-full sm:w-3/4 md:w-1/2 xl:w-2/5;

		&-content {
			@apply flex justify-between text-xs md:text-sm border-b-2;

			& p {
				@apply w-1/2 mt-1 font-normal;
			}

			& p+p{
				@apply ml-2 lg:ml-4;
			}
		}
	}

	&__form {
		@apply w-full md:w-1/2 flex flex-col justify-center items-center;
	}
}
