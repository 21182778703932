.services {
	&__wrapper {
		@apply lg:container py-4 mx-8 lg:mx-auto;
	}
	&__title {
		@apply py-8 uppercase text-4xl font-bold text-colorMain;
	}

	&__sub-title {
		@apply pb-8 uppercase text-base lg:text-xl font-semibold;
	}

	&__blocks {
		@apply flex flex-wrap justify-center items-center gap-8 px-4 xl:px-8;
	}
}
